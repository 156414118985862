<template>
	<div class="p-grid" >
        <div class="p-col-12">
            <div class="card">
                <div class="p-grid">
                    <div class="p-col-9">
                        <h5 class="p-m-0">{{$t('No Claim')}}</h5>
                    </div>
                    <div class="p-col-3">
                        <Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
                        <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Mostrar()" />
                    </div>    
                </div>
            </div>
		</div>
		<div class="p-col-6" v-if="products">
			<div class="card">
				<DataTable :value="products.step" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">{{$t('Pasos')}}</h5>
						</div>
					</template>
					<Column field="id" :header="$t('Paso')">
                        <template #body="slotProps">
                            {{slotProps.data.id}}
                        </template>
                    </Column>
					<Column field="monto" :header="$t('Casco')" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <InputNumber v-model="slotProps.data.casco" suffix="%" min="-100" max="100" style="width: 50%;"/>
                        </template>
					</Column>
					<Column field="monto" :header="$t('No Casco')" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <InputNumber v-model="slotProps.data.no_casco" suffix="%" min="-100" max="100" style="width: 50%;"/>
                        </template>
					</Column>
                </DataTable>
			</div>
		</div>
		<div class="p-col-6" v-if="products">
			<div class="card">
				<Accordion :activeIndex="0">
					<AccordionTab header="Casco">
						<DataTable :value="products.years.filter(val => val.casco == true)" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
							<Column field="year" :header="$t('Año')">
								<template #body="slotProps">
									{{slotProps.data.year}}
								</template>
							</Column>
							<Column field="claim_0" :header="$t('Claim 0')">
								<template #body="slotProps">
									{{slotProps.data.claim_0}}<br>
									<Slider v-model="slotProps.data.claim_0" :min="1" :max="19"/>
								</template>
							</Column>
							<Column field="claim_1" :header="$t('Claim 1')">
								<template #body="slotProps">
									{{slotProps.data.claim_1}}<br>
									<Slider v-model="slotProps.data.claim_1" :min="1" :max="19"/>
								</template>
							</Column>
							<Column field="claim_2" :header="$t('Claim 2+')">
								<template #body="slotProps">
									{{slotProps.data.claim_2}}<br>
									<Slider v-model="slotProps.data.claim_2" :min="1" :max="19"/>
								</template>
							</Column>
						</DataTable>
					</AccordionTab>
					<AccordionTab header="No Casco">
						<DataTable :value="products.years.filter(val => val.casco == false)" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
							<Column field="year" :header="$t('Año')">
								<template #body="slotProps">
									{{slotProps.data.year}}
								</template>
							</Column>
							<Column field="claim_0" :header="$t('Claim 0')">
								<template #body="slotProps">
									{{slotProps.data.claim_0}}<br>
									<Slider v-model="slotProps.data.claim_0" :min="1" :max="19"/>
								</template>
							</Column>
							<Column field="claim_1" :header="$t('Claim 1')">
								<template #body="slotProps">
									{{slotProps.data.claim_1}}<br>
									<Slider v-model="slotProps.data.claim_1" :min="1" :max="19"/>
								</template>
							</Column>
							<Column field="claim_2" :header="$t('Claim 2+')">
								<template #body="slotProps">
									{{slotProps.data.claim_2}}<br>
									<Slider v-model="slotProps.data.claim_2" :min="1" :max="19"/>
								</template>
							</Column>
						</DataTable>
					</AccordionTab>
				</Accordion>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','NoClaim');
    import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
                i18n: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				filters: {},
				submitted: false,
				size: '60vw',
			}
		},
		productService: null,
		created() {
            this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = null;
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult;
				}); 
			},
			saveProduct() {
				Consulta.Procesar('UPD',{
					products: 	this.products,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
                        this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
