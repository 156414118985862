<template>
	<div class="p-grid" >
        <div class="p-col-12">
            <div class="card">
                <div class="p-grid">
                    <div class="p-col-3">
                        <h5 class="p-m-0">{{$t('Deducible')}}</h5>
                    </div>
                    <div class="p-col-7">
                        <Dropdown id="state" v-model="tipo" :options="tipovehiculo" :optionLabel="$t('nombre')" placeholder="?..."></Dropdown>
                    </div>
                    <div class="p-col-2">
                        <Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
                    </div>    
                </div>
            </div>
		</div>
		<div class="p-col-12" v-if="tipo">
			<div class="card">
                <Accordion :activeIndex="0">
                    <AccordionTab header="Casco" v-if="products.filter(val => val.tipovehiculo == tipo.id && val.casco == true).length>0">
                        <DataTable :value="products.filter(val => val.tipovehiculo == tipo.id && val.casco == true)" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
                            <Column field="min" :header="$t('Min')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.min" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="max" :header="$t('Max')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.max" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="monto" :header="$t('Monto')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <InputNumber v-model="slotProps.data.monto" suffix="%" min="0" max="100"   v-if="slotProps.data.porc"/>
                                    <InputNumber v-model="slotProps.data.monto" mode="currency" min="0" :currency="$store.state.currency" v-else/>
                                </template>
                            </Column>
                            <Column field="porc" :header="$t('%')" headerStyle="width: 10%">
                                <template #body="slotProps">
                                    <InputSwitch v-model="slotProps.data.porc" />
                                </template>
                            </Column>
                            <Column field="edad_min" :header="$t('Edad')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.edad_min" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="edad_rec" :header="$t('Recargo')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <InputNumber v-model="slotProps.data.edad_rec" mode="currency" min="0" :currency="$store.state.currency"/>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab header="No Casco" v-if="products.filter(val => val.tipovehiculo == tipo.id && val.casco == false).length>0">
                        <DataTable :value="products.filter(val => val.tipovehiculo == tipo.id && val.casco == false)" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id">
                            <Column field="min" :header="$t('Min')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.min" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="max" :header="$t('Max')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.max" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="monto" :header="$t('Monto')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <InputNumber v-model="slotProps.data.monto" suffix="%" min="0" max="100"   v-if="slotProps.data.porc"/>
                                    <InputNumber v-model="slotProps.data.monto" mode="currency" min="0" :currency="$store.state.currency" v-else/>
                                </template>
                            </Column>
                            <Column field="porc" :header="$t('%')" headerStyle="width: 10%">
                                <template #body="slotProps">
                                    <InputSwitch v-model="slotProps.data.porc" />
                                </template>
                            </Column>
                            <Column field="edad_min" :header="$t('Edad')">
                                <template #body="slotProps">
                                    <InputText type="number" v-model="slotProps.data.edad_min" style="width: 70%"/>
                                </template>
                            </Column>
                            <Column field="edad_rec" :header="$t('Recargo')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <InputNumber v-model="slotProps.data.edad_rec" mode="currency" min="0" :currency="$store.state.currency"/>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Deductible');
    import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
                i18n: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				filters: {},
				submitted: false,
				size: '60vw',
				tipovehiculo: [],
                tipo: null,
			}
		},
		productService: null,
		created() {
            this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = [];
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.tipovehiculo = result.consult.tipovehiculo;
                    //this.tipo = result.consult.tipovehiculo[0];
					this.products = result.consult.deductibles;
				}); 
			},
			saveProduct() {
				Consulta.Procesar('UPD',{
					products: 	this.products,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
                        this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
